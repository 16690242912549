/**
 * Nolaノベルのジャンルに関する定義
 */

/*
 * ジャンル Enum定義
 */
export enum NolaNovelGenre {
  LOVE = 'LOVE',
  FANTASY = 'FANTASY',
  ISEKAI = 'ISEKAI',
  DRAMA = 'DRAMA',
  ACTION = 'ACTION',
  SF = 'SF',
  MYSTERY = 'MYSTERY',
  HORROW = 'HORROW',
  HISTORY = 'HISTORY',
  COMEDY = 'COMEDY',
  CHILDREN = 'CHILDREN',
  POEM = 'POEM',
  NONGENRE = 'NONGENRE',
  OTHER = 'OTHER',
}
