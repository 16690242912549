
import Vue, { PropType } from "vue";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaAnalytics, NolaItemId, NolaPageName } from "@/lib/utils/analytics";
import { NolaNovelUrlGenerator } from "@/lib/utils/generator/nolanovelUrl";
import { showNotifyDialog } from "@/lib/dialog";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    company: Object as PropType<Company>,
    matchedNovelIds: [],
  },
  computed: {
    id() {
      const { company } = this;
      return company.id;
    },
    bannerImg() {
      const { company } = this;
      return `${company.bannerImg.url}`;
    },
    iconImg() {
      const { company } = this;
      return `${company.iconImg.url}`;
    },
    name() {
      const { company } = this;
      return company.name;
    },
    comment() {
      const { company } = this;
      return company.comment;
    },
    numOfNovels() {
      return this.$store.getters["novelModule/novels"].length
    },
    numOfMatchedNovels() {
      const { matchedNovelIds } = this;
      // 描画中の場合など
      if (!matchedNovelIds) return 0;
      return matchedNovelIds.length;
    },
  },
  methods: {
    onClickOpenDetail() {
      const { id } = this;

      const url = createUrlWithUtmParams(
        `${process.env.VUE_APP_NOLANOVEL_WEB}/company/${id}`,
        NolaPageName.EventCompany,
        NolaItemId.CompanyCard
      );
      this.$emit("openDetailFrame", url);
    },
    async onClickPost() {
      try {
        const url = process.env.VUE_APP_NOLANOVEL_WEB;

        if (!url) {
          throw new Error("NolaノベルのWebサイトURLが環境変数に含まれていません。");
        }

        const service = new NolaNovelUrlGenerator(process.env.VUE_APP_NOLANOVEL_WEB!);

        const { tag } = this.company;
        
        const postNovelForCompanyUrl = await service.genPostNovelForCompanyUrl(tag);
        
        // iframeを開いたままwindow.openで開くと、iframeの中で遷移するため、aタグでプログラム的にクリックする
        const link = document.createElement("a");
        link.href = createUrlWithUtmParams(postNovelForCompanyUrl, NolaPageName.NolaNovel, NolaItemId.PostToNolaNovelFromCompany);
        link.target = "_blank";
        link.click();
        link.remove();

        const nolaAnalytics = new NolaAnalytics(this.$gtm, this.$store);
        nolaAnalytics.logButtonEvent(NolaItemId.PostToNolaNovelFromCompany, "nola-web")
      } catch (_) {
        await showNotifyDialog({
          title: "エラー",
          content: "作品の持ち込み投稿に失敗しました。",
        })
      }
    },
  },
});

interface Props {
  company: Company;
  matchedNovelIds: Array<string>;
}

interface Data {}

interface Computed {
  id: string;
  bannerImg: string;
  iconImg: string;
  name: string;
  comment: string;
  numOfNovels: number;
  numOfMatchedNovels: number;
}

interface Methods {
  onClickOpenDetail(): void;
  onClickPost(): void;
}

type Company = {
  [key: string]: any;
};
