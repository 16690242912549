/**
 * 「Nolaカテゴリ」↔︎「Nolaノベルジャンル」の変換などを行うユーティリティ
 */

import { NolaCategory } from '../constants/nola/nolaCategory';
import { NolaNovelGenre } from '../constants/nolaNovel/nolaNovelGenre';

/*
 * NolaノベルジャンルからNolaカテゴリへの対応表
 */
const genreToCategoryMap: Record<NolaNovelGenre, NolaCategory[]> = {
  [NolaNovelGenre.LOVE]: [
    NolaCategory.ROMANTIC,
    NolaCategory.ROMANTIC_COMEDY,
    NolaCategory.MODERN_ROMANCE,
    NolaCategory.BL,
    NolaCategory.TL,
    NolaCategory.YURI,
    NolaCategory.DIFFERENT_WORLD_ROMANTIC_FANTASY,
  ],
  [NolaNovelGenre.FANTASY]: [
    NolaCategory.FANTASY,
    NolaCategory.LOW_FANTASY,
    NolaCategory.SWASHBUCKLER,
  ],
  [NolaNovelGenre.ISEKAI]: [
    NolaCategory.FANTASY,
    NolaCategory.DIFFERENT_WORLD_FANTASY,
    NolaCategory.DIFFERENT_WORLD_ROMANTIC_FANTASY,
    NolaCategory.SWASHBUCKLER,
  ],
  [NolaNovelGenre.DRAMA]: [
    NolaCategory.ROMANTIC,
    NolaCategory.ROMANTIC_COMEDY,
    NolaCategory.MODERN_ROMANCE,
  ],
  [NolaNovelGenre.ACTION]: [NolaCategory.BATTLE, NolaCategory.SWASHBUCKLER],
  [NolaNovelGenre.SF]: [NolaCategory.SF],
  [NolaNovelGenre.MYSTERY]: [NolaCategory.MYSTERY, NolaCategory.DETECTIVE],
  [NolaNovelGenre.HORROW]: [NolaCategory.HORROR],
  [NolaNovelGenre.HISTORY]: [NolaCategory.HISTORY],
  [NolaNovelGenre.COMEDY]: [NolaCategory.GAG],
  [NolaNovelGenre.CHILDREN]: [NolaCategory.EDUCATION, NolaCategory.CHILDREN],
  [NolaNovelGenre.POEM]: [],
  [NolaNovelGenre.NONGENRE]: [],
  [NolaNovelGenre.OTHER]: [NolaCategory.EROTIC_NOVEL, NolaCategory.OTHER],
};

/**
 * NolaカテゴリからNolaノベルジャンルへの対応表を生成（genreToCategoryMapより自動生成）
 *
 * @param {Record<NolaNovelGenre, NolaCategory[]>} genreToCategoryMap
 * @returns {Record<NolaCategory, NolaNovelGenre>} NolaカテゴリからNolaノベルジャンルへの対応表
 */
const createCategoryToGenreMap = (
  genreToCategoryMap: Record<NolaNovelGenre, NolaCategory[]>,
): Record<NolaCategory, NolaNovelGenre[]> => {
  const map: Partial<Record<NolaCategory, NolaNovelGenre[]>> = {};
  for (const [genre, categories] of Object.entries(genreToCategoryMap)) {
    categories.forEach((category: NolaCategory) => {
      if (!map[category]) {
        map[category] = [];
      }
      map[category]!.push(genre as NolaNovelGenre);
    });
  }
  return map as Record<NolaCategory, NolaNovelGenre[]>;
};

/*
 * NolaカテゴリからNolaノベルジャンルへの対応表
 */
const categoryToGenreMap: Record<NolaCategory, NolaNovelGenre[]> =
  createCategoryToGenreMap(genreToCategoryMap);

/*
 * CategoryUtilsクラス
 */
export class CategoryUtils {
  /**
   * NolaノベルジャンルからNolaカテゴリへの変換
   * stringとNolaNovelGenreの両方を受け入れる形式にすることで、呼び出し側で柔軟性を確保しつつ、型の恩恵を失わないようにする
   *
   * @param {string | NolaNovelGenre} genre NolaノベルジャンルID
   * @return {NolaCategory[]} NolaカテゴリIDの配列
   */
  static convertToNolaCategories(
    genre: string | NolaNovelGenre,
  ): NolaCategory[] {
    return genreToCategoryMap[genre as NolaNovelGenre] ?? [];
  }

  /**
   * NolaカテゴリからNolaノベルジャンルへの変換
   * stringとNolaCategoryの両方を受け入れる形式にすることで、呼び出し側で柔軟性を確保しつつ、型の恩恵を失わないようにする
   *
   * @param {string | NolaCategory} category NolaカテゴリID
   * @return {NolaNovelGenre[]} NolaノベルジャンルIDの配列
   */
  static convertToNolaNovelGenre(
    category: string | NolaCategory,
  ): NolaNovelGenre[] {
    return categoryToGenreMap[category as NolaCategory] ?? [];
  }
}
