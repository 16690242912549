
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import EventHeader from "@/components/organisms/EventHeader.vue";
import CompanyCard from "@/components/molecules/CompanyCard.vue";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";
import { CategoryUtils } from '@/../nolaplatform-commons/src/utils/categoryGenreUtils';
import { Dialog } from "@/lib/utils";
import EventCompanyDialog from "@/components/ui/EventCompanyDialog.vue";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_NOLANOVEL_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_NOLANOVEL_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, EventHeader, CompanyCard },
  data() {
    return {
      isLoading: true,
      companies: [],
      companyMatchedNovelIds: {},
      iframeCompanyUrl: "",
      iframeHeight: 0,
      iframeTop: 100,
    };
  },
  async created() {
    // ポップアップ表示（初回のみ）
    if (this.$store.getters["generalModule/isVisibleNolaNovelCompanyRecommendPopup"]) {
      const eventCompanyDialog = new Dialog(EventCompanyDialog);
      eventCompanyDialog.show();

      // 次回以降ポップアップを非表示にする
      await this.$store.dispatch("generalModule/setVisibleNolaNovelCompanyRecommendPopup", false);
    }

    const companyRequestUrl = "/company?limit=100";
    const company = await axios.get(companyRequestUrl);

    if (company.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "編集部情報の取得に失敗しました。",
      });
    }

    this.companies = company.data.contents;

    // 自身の作品の中から編集部のジャンルと一致する作品を抽出する
    this.extractCompanyTargetNovels();

    this.isLoading = false;
  },
  mounted() {
    // windowがリサイズされた際にiframeの高さを更新する
    window.addEventListener("resize", this.updateIframeHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIframeHeight);
  },
  methods: {
    /**
     * 編集部のジャンルと一致する作品を抽出する
     */
    extractCompanyTargetNovels() {
      // 自身の作品を取得
      const novels = this.$store.getters["novelModule/novels"];

      novels.forEach((novel) => {
        // 既にNolaノベルへの連携がある場合はスキップ
        if (novel.associatedData) {
          return;
        }
        // 作品のカテゴリがない場合はスキップ
        if (!novel.category) {
          return;
        }

        // 作品のカテゴリをNolaノベルのジャンルに変換
        const nolaNovelGenreArray = novel.category.map((category) => CategoryUtils.convertToNolaNovelGenre(category));
        // 1カテゴリごとに配列で帰ってくるため、フラットにする
        const nolaNovelGenreArrayFlat = nolaNovelGenreArray.flat();

        // eslint-disable-next-line no-restricted-syntax
        for (const company of this.companies) {
          if (!this.companyMatchedNovelIds[company.id]) {
            this.companyMatchedNovelIds[company.id] = [];
          }
          // 編集部のジャンルと一致する作品を抽出 編集部の求めているジャンルは複数あるためforEachで回す
          // eslint-disable-next-line no-restricted-syntax
          for (const genre of company.novelGenreForSearch) {
            if (nolaNovelGenreArrayFlat.includes(genre)) {
              this.companyMatchedNovelIds[company.id].push(novel.novelId);

              // 一つでも一致したら次の編集部へ
              break;
            }
          }
        }
      });
    },
    /**
     * iframeを開き、urlで指定した編集部の"Nolaノベル - 編集部の掲示板"を表示する
     * @param url {string} - iframeを開くためのURL
     */
    openDetailFrame(url: string) {
      // iframeを開くためのURLを設定 - 設定したら表示される
      this.iframeCompanyUrl = url;
      this.updateIframeHeight();
    },
    /**
     * iframeを閉じる
     */
    closeDetailFrame() {
      // iframeCompanyUrlを空にすることでiframeが非表示になる
      this.iframeCompanyUrl = "";
    },
    /**
     * iframeの表示領域の高さを取得
     */
    updateIframeHeight() {
      // iframeのwindow sizeを取得
      const iframeWindowSize = window.innerHeight - this.iframeTop;
      // styleのiframeクラスにて、0.8倍に縮小表示しているため、1.25倍にして表示領域を確保
      this.iframeHeight = iframeWindowSize * 1.25;
    },
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  companies: Company[];
  companyMatchedNovelIds: { [key: string]: string[] };
  iframeCompanyUrl: string;
  iframeHeight: number;
  // iframeをposition: fixedで固定するための上からの位置 (表示領域の算出に使用するためscriptで保持)
  iframeTop: number;
}

interface Computed {
}

interface Methods {
  extractCompanyTargetNovels: () => void;
  openDetailFrame: (url: string) => void;
  closeDetailFrame: () => void;
  updateIframeHeight: () => void;
}

interface Company {
  id: string;
}
