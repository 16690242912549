
import Vue from "vue";
import { Auth } from "aws-amplify";
import { format } from "date-fns";
import { z } from "zod";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import ContestEntryUserForm from "@/components/organisms/event/ContestEntryUserForm.vue";
import { NolaContestUser } from "@/lib/models/nolaContest";
import { ValidationError } from "./EventContestEntry.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ContestEntryUserForm,
  },
  data() {
    return {
      entryUser: {},
      cognitoUserEmail: "",
      errors: [],
      fetched: false,
    };
  },
  async created() {
    await this.$store.dispatch("nolaContestModule/fetchUser");

    const user = await Auth.currentUserPoolUser();
    this.cognitoUserEmail = user.attributes.email;

    this.fetched = true;
  },
  computed: {
    buttonText() {
      return "保存する";
    },
    contestUser() {
      return this.$store.getters["nolaContestModule/user"];
    },
    formattedEntryUser() {
      const { entryUser, contestUser } = this;
      const { name, nameKana, email, phone, gender, birthdate, historyOfAwards } = entryUser;

      return {
        name,
        nameKana,
        penname: contestUser.penname,
        email: email || undefined,
        phone,
        gender: gender && gender.id,
        birthdate: birthdate && format(birthdate, "YYYY/MM/DD"),
        historyOfAwards,
      };
    },
  },
  methods: {
    async onClickButton() {
      if (!this.validationEntryUser()) return;

      const payload: NolaContestUser = {
        ...this.formattedEntryUser,
      };
      await this.$store.dispatch("nolaContestModule/updateUser", payload);

      this.$notify({
        title: "保存しました",
        type: "success",
      });
    },
    validationEntryUser() {
      let errorMessages: ValidationError[] = [];

      // ふりがなの正規表現
      const nameKanaRegExp = new RegExp("^[\u3040-\u309F\u30FC\\s]+$");

      // 電話番号の正規表現
      const phoneRegExp = new RegExp("^0\\d{9,10}$");

      /** 応募者のスキーマ定義 */
      const userSchema = z.object({
        name: z.string().max(100).nullish(),
        nameKana: z
          .string()
          .max(100)
          .nullish()
          .refine((value) => !value || nameKanaRegExp.test(value), "ひらがなのみで入力してください。"),
        penname: z.array(z.string().min(1).max(100)).max(10).nullish(),
        email: z.string().email().nullish(),
        phone: z
          .string()
          .nullish()
          .refine((value) => !value || phoneRegExp.test(value), "電話番号を正しく入力してください。"),
        gender: z.union([z.literal(1), z.literal(2), z.literal(9)]).nullish(),
        birthdate: z
          .string()
          .nullish()
          .refine((date) => !date || format(date, "YYYY/MM/DD") === date),
        historyOfAwards: z.string().max(500).nullish(),
      });

      // Zodでのバリデーション
      const zodValidation = userSchema.safeParse(this.formattedEntryUser);
      if (!zodValidation.success) {
        errorMessages = zodValidation.error.issues.map((issue) => ({
          path: issue.path.join("."),
          message: issue.message,
        }));
      }

      this.errors = errorMessages;

      if (errorMessages.length) {
        const errorDialog = new Dialog(SimpleDialog);
        const errorData: SimpleDialogProps = {
          title: "エラー",
          content: "<span>入力内容に不備があります。</span><br /><span>エラーを確認してください。</span>",
          isError: true,
        };
        errorDialog.show(errorData);
        return false;
      }

      return true;
    },
  },
});

interface Props {}

interface Data {
  entryUser: { [key: string]: any };
  cognitoUserEmail: string;
  errors: ValidationError[];
  fetched: boolean;
}

interface Computed {
  buttonText: string;
  contestUser: NolaContestUser;
  formattedEntryUser: NolaContestUser;
}

interface Methods {
  onClickButton(): void;
  validationEntryUser(): boolean;
}
