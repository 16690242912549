
import Vue, { PropType } from "vue";
import DatePicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import ContestEntryItem from "@/components/molecules/ContestEntryItem.vue";
import InputTextV2 from "@/components/atoms/InputTextV2.vue";
import SelectBoxV2, { Option } from "@/components/atoms/SelectBoxV2.vue";
import CheckboxItem from "@/components/atoms/CheckboxItem.vue";
import TagItem from "@/components/atoms/TagItem.vue";
import { Gender, NolaContest, NolaContestUser } from "@/lib/models/nolaContest";
import { Dialog } from "@/lib/utils";
import InputDialog, { InputDialogProps } from "@/components/ui/dialogs/InputDialog.vue";
import BreakingChangeConfirm, { BreakingChangeConfirmProps } from "@/components/ui/dialogs/BreakingChangeConfirm.vue";
import deepEqual from "deep-equal";
import { ValidationError } from "@/pages/EventContestEntry.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ContestEntryItem,
    InputTextV2,
    SelectBoxV2,
    CheckboxItem,
    TagItem,
    DatePicker,
  },
  props: {
    value: Object,
    initialValue: {
      type: Object as PropType<object>,
      default: () => ({}),
      required: false,
    },
    contest: {
      type: Object as PropType<NolaContest>,
      required: false,
    },
    cognitoUserEmail: String as PropType<string>,
    isConfirm: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isEdit: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errors: {
      type: Array as PropType<ValidationError[]>,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      ja,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    contestUser() {
      return this.$store.getters["nolaContestModule/user"];
    },
    genderOption() {
      return (id) => this.genderOptions.find((option) => option.id === id)!;
    },
    genderOptions() {
      return Object.entries(Gender)
        .filter(([_, value]) => !Number.isNaN(Number(value)))
        .map(([key, value]) => ({
          id: value,
          name: key,
        }));
    },
    pennameOptions() {
      const { contestUser } = this;

      if (!contestUser || !contestUser.penname) return [];
      return (contestUser.penname as string[]).map((user) => ({
        id: user,
        name: user,
      }));
    },
    errorMessage() {
      return (type) => {
        const error = this.errors.find((error) => error.path === type);
        return error && error.message;
      };
    },
  },
  methods: {
    onClickAddPenname() {
      const dialog = new Dialog(InputDialog);
      const data: InputDialogProps = {
        title: "ペンネームの追加",
        close: "キャンセル",
        submit: "作成する",
        submitHandler: (value: string) => {
          const penname = [...((this.contestUser && this.contestUser.penname) ?? []), value];
          const payload: NolaContestUser = {
            ...this.contestUser,
            penname,
          };
          this.$store.dispatch("nolaContestModule/updateUser", payload);

          this.internalValue.pennames = penname;
        },
      };
      dialog.show(data);
    },
    async onClickDeletePenname(value) {
      const confirmDialog = new Dialog(BreakingChangeConfirm);
      const data: BreakingChangeConfirmProps = {
        title: "ペンネームの削除",
        content: `本当に「${value}」を削除しますか？<br />※ 一度削除したデータは元に戻せません。`,
        change: "削除する",
      };
      const result = await confirmDialog.show(data);

      if (!result) {
        return;
      }

      const penname = (this.contestUser!.penname as string[]).filter((x) => x !== value);
      const payload: NolaContestUser = {
        ...this.contestUser,
        penname,
      };
      this.$store.dispatch("nolaContestModule/updateUser", payload);

      this.internalValue.pennames = penname;
    },
  },
  watch: {
    contestUser: {
      handler(contestUser: NolaContestUser) {
        // NOTE: 保存済みの応募者情報がある場合は、初期値として設定する
        if (deepEqual(this.value, this.initialValue)) {
          let formattedContestUser: { [key: string]: any } = {
            email: this.cognitoUserEmail,
            ...this.initialValue,
          };

          if (contestUser) {
            formattedContestUser = {
              ...formattedContestUser,
              name: contestUser.name,
              nameKana: contestUser.nameKana,
              email: contestUser.email ?? this.cognitoUserEmail,
              phone: contestUser.phone,
              gender: contestUser.gender && this.genderOption(contestUser.gender),
              birthdate: contestUser.birthdate,
              historyOfAwards: contestUser.historyOfAwards,
            };
          }

          this.$emit("input", formattedContestUser);
        }
      },
      deep: true,
      immediate: true,
    },
  },
});

interface Props {
  value: { [key: string]: any };
  initialValue: { [key: string]: any };
  contest?: NolaContest;
  cognitoUserEmail: string;
  isConfirm: boolean;
  isEdit: boolean;
  errors: ValidationError[];
}

interface Data {
  ja: typeof ja;
}

interface Computed {
  internalValue: { [key: string]: any };
  contestUser: NolaContestUser | null;
  genderOption(id: Gender): Option;
  genderOptions: Option[];
  pennameOptions: Option[];
  errorMessage(type: string): string | undefined;
}

interface Methods {
  onClickAddPenname(): void;
  onClickDeletePenname(penname: string): Promise<void>;
}
