
import Vue from "vue";
import { Auth } from "aws-amplify";
import axiosBase from "axios";
import { z } from "zod";
import { format } from "date-fns";
import { generateEntryItemSchema } from "@/lib/zod";
import { showNotifyDialog } from "@/lib/dialog";
import { Dialog } from "@/lib/utils";
import SimpleDialog, { SimpleDialogProps } from "@/components/ui/dialogs/SimpleDialog.vue";
import {
  ContestEntryItemModel,
  EntryContestInput,
  NolaContest,
  NolaContestData,
  NolaContestUser,
} from "@/lib/models/nolaContest";
import ContestEntryDataForm from "@/components/organisms/event/ContestEntryDataForm.vue";
import ContestEntryUserForm from "@/components/organisms/event/ContestEntryUserForm.vue";
import ContestEntryComplete from "@/components/organisms/event/ContestEntryComplete.vue";
import { Character, Manuscript } from "@/lib/models";
import { Option } from "@@/atoms/SelectBoxV2.vue";
import deepEqual from "deep-equal";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

const Steps = {
  EntryData: "entryData",
  EntryUser: "entryUser",
  Confirm: "confirm",
  Complete: "complete",
} as const;
type StepType = typeof Steps[keyof typeof Steps];

const initialValueEntryUser = {
  isSaveEntryUser: true,
  isAgreedTerms: false,
};

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    ContestEntryDataForm,
    ContestEntryUserForm,
    ContestEntryComplete,
  },
  props: {
    id: String,
    contestId: String,
  },
  async created() {
    const contestRequest = await axios.get(`/contest/${this.id}`);
    if (contestRequest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }
    this.contestImage = contestRequest.data.image.url;

    const contest = this.$store.getters["nolaContestModule/contest"](this.contestId);
    if (!contest) this.$store.dispatch("nolaContestModule/fetchContest", this.contestId);

    this.$store.dispatch("nolaContestModule/fetchUser");

    this.$store.dispatch("novelModule/initialize");

    const user = await Auth.currentUserPoolUser();
    this.cognitoUserEmail = user.attributes.email;
  },
  data() {
    return {
      Steps,
      currentStep: Steps.EntryData,
      initialValueEntryUser,
      entryData: {
        entryItems: {},
      },
      entryUser: initialValueEntryUser,
      contestImage: undefined,
      cognitoUserEmail: "",
      errors: [],
    };
  },
  computed: {
    contest() {
      return this.$store.getters["nolaContestModule/contest"](this.contestId);
    },
    contestEntryItems() {
      const { entryItems } = this.contest;
      const entryItemsWithCategory =
        (this.entryData.category &&
          this.contest.categories!.find((category) => category.id === this.entryData.category.id)!.entryItems) ||
        [];
      const margedItems = [...entryItems, ...entryItemsWithCategory] as ContestEntryItemModel[];
      const sortedItems = margedItems.sort((a, b) => (a.order || 0) - (b.order || 0));
      return sortedItems;
    },
    contestUser() {
      return this.$store.getters["nolaContestModule/user"];
    },
    formattedEntryData() {
      const { entryData, contestEntryItems } = this;
      const { novel, category, entryItems } = entryData;

      const formattedEntryItems = Object.fromEntries(
        Object.entries(entryItems).map(([key, value]) => {
          const targetItem = contestEntryItems.find((item) => item.id === key);
          if (!targetItem) return [key, value];

          switch (targetItem.type) {
            case "string":
            case "number":
            case "title":
            case "synopsis":
              return [key, value];
            case "select":
            case "plot":
              return [key, (value as Option).id];
            case "manuscripts":
              return [key, (value as Manuscript[]).map((manuscript) => manuscript.key)];
            case "characters":
              return [key, (value as Character[]).map((character) => character.characterId)];
            default:
              return [key, value];
          }
        })
      );

      return {
        novelId: novel.id,
        category: category ? ((category as Option).id as string) : null,
        entryItems: formattedEntryItems,
      };
    },
    formattedEntryUser() {
      const { entryUser } = this;
      const { name, nameKana, penname, email, phone, gender, birthdate, historyOfAwards } = entryUser;

      return {
        name,
        nameKana,
        penname: penname && penname.id,
        email,
        phone,
        gender: gender && gender.id,
        birthdate: birthdate && format(birthdate, "YYYY/MM/DD"),
        historyOfAwards,
      };
    },
  },
  methods: {
    async onClickTransition(step) {
      let result = false;

      switch (this.currentStep) {
        case Steps.EntryData:
          if (step === Steps.EntryUser) result = this.validationEntryData();
          else result = true;
          break;
        case Steps.EntryUser:
          if (step === Steps.Confirm) result = this.validationEntryUser();
          else result = true;
          break;
        case Steps.Confirm:
          if (step === Steps.Complete) {
            const saveUserResult = this.entryUser.isSaveEntryUser ? await this.saveEntryUser() : true;
            const submitEntryResult = await this.submitEntry();
            result = saveUserResult && submitEntryResult;
          } else result = true;
          break;
        default:
          break;
      }

      if (!result) return;

      this.currentStep = step;
      (this.$refs.eventContestEntry as Element).scrollTop = 0;
    },
    onClickToTop() {
      this.$router.push("/");
    },
    validationEntryData() {
      const { contest } = this;
      let errorMessages: ValidationError[] = [];

      /** 募集部門のスキーマ定義 */
      const categorySchema = (() => {
        if (!contest.categories) {
          return z.null().or(z.undefined());
        }
        const categoryIds = contest.categories.map((category) => category.id) as [string, ...string[]];
        return z.enum(categoryIds);
      })();

      /** 入力項目のスキーマ定義 */
      const entryItemSchema = z.object(
        this.contestEntryItems.reduce((obj, item) => {
          // eslint-disable-next-line no-param-reassign
          obj[item.id] = generateEntryItemSchema(item);
          return obj;
        }, {} as { [key: string]: z.ZodType<any, z.ZodTypeDef, any> })
      );

      /** リクエストのスキーマ定義 */
      const entryDataSchema = z.object({
        novelId: z.string(),
        category: categorySchema,
        entryItems: entryItemSchema,
      });

      // Zodでのバリデーション
      const zodValidation = entryDataSchema.safeParse(this.formattedEntryData);
      if (!zodValidation.success) {
        errorMessages = zodValidation.error.issues.map((issue) => ({
          path: issue.path.join("."),
          message: issue.message,
        }));
      }

      // 原稿のバリデーション
      const selectedManuscripts = this.entryData.entryItems.manuscripts as Manuscript[];
      const manuscriptInContest = this.contestEntryItems.find((item) => item.type === "manuscripts");
      if (
        selectedManuscripts &&
        manuscriptInContest &&
        manuscriptInContest.type === "manuscripts" &&
        (manuscriptInContest.min || manuscriptInContest.max)
      ) {
        const totalTextCount = selectedManuscripts.reduce(
          (count, manuscript) => count + (manuscript.content ? manuscript.content.length : 0),
          0
        );

        if (manuscriptInContest.min && totalTextCount < manuscriptInContest.min) {
          errorMessages.push({
            path: `entryItems.${manuscriptInContest.id}`,
            message: `原稿の合計文字数を${manuscriptInContest.min.toLocaleString()}文字以上にしてください。`,
          });
        }
        if (manuscriptInContest.max && totalTextCount > manuscriptInContest.max) {
          errorMessages.push({
            path: `entryItems.${manuscriptInContest.id}`,
            message: `原稿の合計文字数を${manuscriptInContest.max.toLocaleString()}文字以下にしてください。`,
          });
        }
      }

      this.errors = errorMessages;

      if (errorMessages.length) {
        const errorDialog = new Dialog(SimpleDialog);
        const errorData: SimpleDialogProps = {
          title: "エラー",
          content: "<span>入力内容に不備があります。</span><br /><span>エラーを確認してください。</span>",
          isError: true,
        };
        errorDialog.show(errorData);
        return false;
      }

      return true;
    },
    validationEntryUser() {
      let errorMessages: ValidationError[] = [];

      // ふりがなの正規表現
      const nameKanaRegExp = new RegExp("^[\u3040-\u309F\u30FC\\s]+$");

      // 電話番号の正規表現
      const phoneRegExp = new RegExp("^0\\d{9,10}$");

      // メールアドレスのバリデーション
      const emailValidation =
        this.entryUser.email === this.cognitoUserEmail || this.entryUser.email === this.entryUser.emailConfirm;

      /** 応募者のスキーマ定義 */
      const userSchema = z.object({
        name: z.string().max(100).nonempty(),
        nameKana: z
          .string()
          .max(100)
          .refine((value) => nameKanaRegExp.test(value), "ひらがなのみで入力してください。"),
        penname: z.string().max(100).nonempty(),
        email: z
          .string()
          .email()
          .refine(() => emailValidation, "メールアドレスが一致しません。"),
        phone: z
          .string()
          .min(10)
          .max(11)
          .refine((value) => phoneRegExp.test(value), "電話番号を正しく入力してください。"),
        gender: z.union([z.literal(1), z.literal(2), z.literal(9)]),
        birthdate: z.string().refine((date) => format(date, "YYYY/MM/DD") === date),
        historyOfAwards: z.string().max(500).nullish(),
      });

      // Zodでのバリデーション
      const zodValidation = userSchema.safeParse(this.formattedEntryUser);
      if (!zodValidation.success) {
        errorMessages = zodValidation.error.issues.map((issue) => ({
          path: issue.path.join("."),
          message: issue.message,
        }));
      }

      this.errors = errorMessages;

      if (errorMessages.length) {
        const errorDialog = new Dialog(SimpleDialog);
        const errorData: SimpleDialogProps = {
          title: "エラー",
          content: "<span>入力内容に不備があります。</span><br /><span>エラーを確認してください。</span>",
          isError: true,
        };
        errorDialog.show(errorData);
        return false;
      }

      return true;
    },
    async saveEntryUser() {
      const contestUser = {
        ...this.formattedEntryUser,
        penname: this.contestUser.penname ?? [],
      };
      await this.$store.dispatch("nolaContestModule/updateUser", contestUser);

      return true;
    },
    async submitEntry() {
      const entryItems = {
        ...this.formattedEntryData.entryItems,
        user: this.formattedEntryUser,
      };
      const payload: EntryContestInput = {
        ...this.formattedEntryData,
        entryItems,
        contestId: this.contestId,
        platform: this.contest.platform,
      };

      try {
        await this.$store.dispatch("nolaContestModule/entryContest", payload);

        return true;
      } catch (error: any) {
        const errorDialog = new Dialog(SimpleDialog);
        const errorData: SimpleDialogProps = {
          title: "エラー",
          content: `<span>${error.response.data}</span>`,
          isError: true,
        };
        errorDialog.show(errorData);
        return false;
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "entryData.novel": function (newValue, oldValue) {
      if (!deepEqual(newValue, oldValue)) {
        this.entryData.entryItems = {};
        this.errors = [];

        this.$store.dispatch("plotModule/initialize", newValue.id);
        this.$store.dispatch("characterModule/initialize", newValue.id);
        this.$store.dispatch("manuscriptModule/initialize", { novelId: newValue.id });
      }
    },
  },
});

interface Props {
  /** ID assigned by microCMS */
  id: string;
  /** ID assigned by NolaContest */
  contestId: string;
}

interface Data {
  Steps: typeof Steps;
  currentStep: StepType;
  initialValueEntryUser: { [key: string]: any };
  entryData: { [key: string]: any };
  entryUser: { [key: string]: any };
  contestImage?: string;
  cognitoUserEmail: string;
  errors: ValidationError[];
}

interface Computed {
  contest: NolaContest;
  contestEntryItems: ContestEntryItemModel[];
  contestUser: NolaContestUser;
  formattedEntryData: NolaContestData;
  formattedEntryUser: NolaContestUser;
}

interface Methods {
  onClickTransition(step: StepType): Promise<void>;
  onClickToTop(): void;
  validationEntryData(): boolean;
  validationEntryUser(): boolean;
  saveEntryUser(): Promise<boolean>;
  submitEntry(): Promise<boolean>;
}

export interface ValidationError {
  path: string;
  message: string;
}
