/**
 * Nolaのカテゴリに関する定義
 */

/*
 * カテゴリ Enum定義
 */
export enum NolaCategory {
  FANTASY = 'fantasy',
  LOW_FANTASY = 'lowFantasy',
  DIFFERENT_WORLD_FANTASY = 'differentWorldFantasy',
  DIFFERENT_WORLD_ROMANTIC_FANTASY = 'differentWorldRomanticFantasy',
  MODERN_ROMANCE = 'modernRomance',
  ROMANTIC = 'romantic',
  ROMANTIC_COMEDY = 'romanticComedy',
  BATTLE = 'battle',
  SF = 'SF',
  MYSTERY = 'mystery',
  HORROR = 'horror',
  DETECTIVE = 'detective',
  GAG = 'gag',
  SWASHBUCKLER = 'swashbuckler',
  HISTORY = 'history',
  BL = 'BL',
  YURI = 'yuri',
  TL = 'TL',
  EROTIC_NOVEL = 'eroticNovel',
  EDUCATION = 'education',
  CHILDREN = 'children',
  OTHER = 'other',
  FAN_FICTION = 'fanFiction',
}
