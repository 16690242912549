
import Vue from "vue";
import Close from "icons/Close.vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    Close,
  },
  props: {
  },
  methods: {
    onClickClose() {
      this.$close(true);
    },
  },
});

interface Data {}

interface Methods {
  onClickClose: () => void;
}

interface Props {}

interface Computed {}
